<template>
    <v-card class="ma-4">
        <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
                   elevation="1">

            <v-toolbar-title> {{ selectedItems.length ? `Selected (${selectedItems.length})  rows` : title }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-text-field
                    v-if="!selectedItems.length"
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>


            <v-btn
                    v-if="selectedItems.length"
                    color="grey darken-2"
                    dark
                    @click.stop="restoreItems"
            >Restore
            </v-btn>

            <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="mx-11"
                            color="#082d3c"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >New
                    </v-btn>
                </template>

                <v-card v-if="dialogAddItem">
                    <v-toolbar
                            color="blue-grey"
                            dark
                    >
                        <v-btn
                                dark
                                icon
                                @click="dialogAddItem = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    :disabled="(!valid || buttonLoading)"
                                    :loading="buttonLoading"
                                    dark
                                    text
                                    @click="validate"
                            >Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-alert
                            v-if="Object.keys(errors).length>0"
                            dense
                            text
                            type="error"
                    >
                        <h4 class="subtitle">Correct the following errors:</h4>
                        <ul>
                            <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                            >{{ error.message || error }}
                            </li>
                        </ul>
                    </v-alert>

                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="name" :counter="150" :rules="nameRules" clearable label="Product name"
                                                      outlined required></v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-select
                                                v-model="itemCategory"
                                                :items="categories"
                                                :rules="[v => !!v || 'Please select category name']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Category name"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-select
                                                v-model="itemManufacturer"
                                                :items="manufacturers"
                                                :rules="[v => !!v || 'Please select manufacturer']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Manufacturer name"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-text-field v-model="price" label="Product price" min="0"
                                                      outlined required
                                                      type="number"></v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                      <v-text-field v-model="code" :counter="150" clearable label="Product code" outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="wattage" label="Product wattage" min="0"
                                                      outlined required
                                                      type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="warranty" label="Product warranty" min="0"
                                                      outlined required
                                                      type="number"></v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                        <v-checkbox
                                                v-model="isActiveDiscount"
                                                color="red"
                                                label="is Product has discount ?"
                                        ></v-checkbox>
                                    </v-col>

                                    <v-col v-if="isActiveDiscount" cols="6">
                                        <v-text-field v-model="discount" label="Product discount" min="0"
                                                      outlined required
                                                      type="number"></v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <v-text-field v-model="subtitle" :counter="150" clearable label="Subtitle" outlined
                                                      required></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea v-model="description" :counter="1000" :rules="describeRules" auto-grow clearable filled
                                                    label="Description"
                                                    required></v-textarea>
                                    </v-col>


                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isActive"
                                                color="red"
                                                label="show addon in the list of addons"
                                        ></v-checkbox>
                                    </v-col>


                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isActiveQuantity"
                                                color="red"
                                                label="Show Quantity in Cart?"
                                        ></v-checkbox>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">

                                        <v-file-input
                                                v-model="image"
                                                accept="image/png, image/jpeg"
                                                color="primary"
                                                label="Upload addon image"
                                                placeholder="Addon Image"
                                                prepend-icon="mdi-camera"
                                        >
                                            <template v-slot:selection="{ text }">
                                                <v-chip
                                                        color="primary"
                                                        label
                                                        small
                                                >
                                                    {{ text }}
                                                </v-chip>
                                            </template>
                                        </v-file-input>

                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                        <v-img v-if="imageUrl" :src="imageUrl" aspect-ratio="1.7" contain></v-img>

                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-btn
                    v-else
                    class="mx-11"
                    color="grey darken-2"
                    dark
                    @click.stop="showDialogDelete"
            >Delete
            </v-btn>
            <v-btn
                    v-if="selectedItems.length"
                    icon
                    @click="selectedItems = []"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-menu
                    v-else
                    :close-on-content-click="false"
                    :nudge-width="250"
                    offset-x
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                        v-model="isShowDeleted"
                                        color="indigo"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                        v-model="isForceDeleted"
                                        color="indigo"
                                ></v-switch>
                            </v-list-item-action>
                            <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-toolbar>

        <v-card-text>
            <v-dialog
                    v-model="dialogDelete"
                    max-width="425"
                    persistent
            >
                <v-card>
                    <v-card-title>
                        Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
                    </v-card-title>

                    <v-card-text class=" ">
                        Are you sure for continue deleting these data ?
                    </v-card-text>

                    <v-card-actions>
                        <v-checkbox
                                v-model="isForceDeletedRow"
                                :disabled=disableChangeDelete
                                :ripple=false
                                class="ma-2 red--text "
                                color="red"
                                hide-details
                        >
                            <template v-slot:label>
                                <div class="red--text body-2">
                                    Delete data forever
                                </div>
                            </template>
                        </v-checkbox>

                        <v-spacer></v-spacer>

                        <v-btn
                                :disabled="buttonLoading"
                                class="body-2"
                                color="primary"
                                text
                                @click="hideDialogDelete()"
                        >
                            Reject
                        </v-btn>

                        <v-btn
                                :disabled="buttonLoading"
                                class="body-2"
                                color="red darken-2"
                                text
                                @click="destroyItems"
                        >
                            Process
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                    v-if="!dialogDelete && !dialogAddItem"
                    v-model="buttonLoading"
                    hide-overlay
                    persistent
                    width="300"
            >
                <v-card
                        color="indigo"
                        dark
                >
                    <v-card-text>
                        <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

                        <v-progress-linear
                                class="my-2"
                                color="white"
                                indeterminate
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-data-table
                    v-model="selectedItems"
                    :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                    :headers="headers"
                    :item-class="(item)=>item.deletedAt?'deletedRow':null"
                    :items="desserts"
                    :items-per-page="10"
                    :loading="loading"
                    :options.sync="options"
                    :search="search"
                    checkbox-color="'blue'"
                    item-key='id'
                    show-select
            >


                <template v-slot:header.data-table-select="{props,on}">
                    <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
                </template>
                <template v-slot:item.data-table-select="{isSelected,select}">
                    <v-simple-checkbox :ripple=false :value="isSelected" color="primary" @input="select($event)"></v-simple-checkbox>
                </template>
                <template v-slot:item.id="{ item }">
                    <v-chip>{{ item.id }}</v-chip>
                </template>
                <template v-slot:item.deletedAt="{ item }">
                    <v-icon
                            v-if="item.deletedAt"
                            class="mr-3"
                            color="primary"
                            small
                            @click="restoreItems(item)"
                    >mdi-restore
                    </v-icon>


                    <v-chip
                            color="indigo"
                            label
                            outlined
                            small
                            @click="editItem(item)"
                    >
                        <v-icon small>
                            mdi-pencil
                        </v-icon>
                    </v-chip>


                    <v-chip
                            :color="item.deletedAt ?'orange':'red accent-4'"
                            class="ml-3"
                            label
                            outlined
                            small
                            @click="showDialogDelete(item)"
                    >
                        <v-icon small>
                            {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
                        </v-icon>
                    </v-chip>

                </template>


                <template v-slot:item.isActive="{ item }">
                    <v-icon :color="(item.isActive)?'green':'red'">
                        mdi-eye
                    </v-icon>
                </template>

                <template v-slot:item.name="{ item }">
                    <v-chip>{{ item.name }}</v-chip>
                </template>

                <template v-slot:item.price="{ item }">
                    <v-chip color="blue-grey" dark>{{ item.price }}</v-chip>
                </template>

                <template v-slot:item.categoryID="{ item }">
                    <v-chip color="orange" dark outlined>{{ getCategoryName(item) }}</v-chip>
                </template>


            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {pathImageUrl, urlAddons, urlCategories, urlCreateAddon, urlManufacturers, urlUpdateAddon} from "@/config";

export default {
    data: () => ({
        search: null,
        dialogDelete: false,
        disableChangeDelete: false,
        deleteItemIDs: [],
        selectedItems: [],
        valid: true,
        id: 0,
        name: '',
        nameRules: [
            v => !!v || 'name is required',
            v => (v && v.length <= 150) || 'name must be less than 150 characters',
        ],
        describeRules: [
            v => (!v || v.length <= 1000) || 'describe must be less than 100 characters',
        ],
        isActive: true,
        isActiveDiscount: false,
        isActiveQuantity: false,
        itemCategory: null,
        categories: [],
        itemManufacturer: null,
        manufacturers: [],
        code: '', warranty: 0, wattage: 0, price: 0, discount: 0, subtitle: '', description: '',

        title: "Addons",
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,
        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: '#', value: 'id'},
            {text: 'Name', value: 'name', align: 'center'},
            {text: 'Category', value: 'categoryID', align: 'center'},
            {text: 'Price', value: 'price', align: 'center'},
            {text: 'Status', value: 'isActive', align: 'center'},
            {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
        ],
        isFormTitleEdit: false,
        image: null,
        imageUrl: null,

    }),

    created() {
        this.getAddonsFromApi();
        this.getCategoriesFromApi();
        this.getManufacturersFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit Addon' : 'Add new addon';
        },
        isShowDeleted: {
            get() {
                return this.$store.getters.isShowDeleted;
            },
            set(val) {
                this.$store.state.isShowDeleted = val;
                this.getAddonsFromApi();
                return val;
            }


        },
        isForceDeleted: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.isForceDeletedRow = val;
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },
        isForceDeletedRow: {
            get() {
                return this.$store.getters.isForceDeleted;
            },
            set(val) {
                this.$store.state.isForceDeleted = val;
                return val;
            }
        },
    },

    watch: {


        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },


    },

    methods: {
        getAddonsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlAddons + this.isShowDeleted)
                .then(response => {

                    this.desserts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getCategoriesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlCategories)
                .then(response => {
                    this.categories = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getManufacturersFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlManufacturers)
                .then(response => {
                    this.manufacturers = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getCategoryName(item) {
            let parent = this.categories.filter(c => c.id == item.categoryID);
            if (parent.length > 0)
                return parent[0].name;
            else return "";
        },

        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {

            const fd = new FormData()

            fd.append("name", this.name);
            fd.append("avatar", this.image);
            fd.append("price", this.price);
            fd.append("discount", this.discount);
            fd.append("code", this.code);
            fd.append("wattage", this.wattage);
            fd.append("warranty", this.warranty);
            fd.append("subtitle", this.subtitle);
            fd.append("description", this.description);
            fd.append("isActiveDiscount", this.isActiveDiscount);
            fd.append("isActiveQuantity", this.isActiveQuantity);
          fd.append("isActive", this.isActive);
            fd.append("manufacturerID", this.itemManufacturer.id);
            fd.append("categoryID", this.itemCategory.id);


            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateAddon + this.id, 2, fd,true)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getAddonsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {

                networks.fetchFromWeb(urlCreateAddon, 1, fd,true)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getAddonsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.name = '';
            this.id = 0;
            this.image = null;
            this.imageUrl = null;
            this.isActive = true;
            this.itemManufacturer = null;
            this.itemCategory = null;
            this.price = null;
            this.discount = null;
            this.wattage = null;
            this.warranty = null;
            this.isActiveQuantity = false;
            this.isActiveDiscount = true;
            this.description = null;
            this.subtitle = null;
          this.code = null;
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {
            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.name = item.name;
            this.price = item.price;
            this.discount = item.discount;
            this.itemManufacturer = {id: item.manufacturerID};
            this.itemCategory = {id: item.categoryID};
          if (item.code != null) this.code = item.code;
          if (item.subtitle != null) this.subtitle = item.subtitle;
          if (item.description != null) this.description = item.description;
            this.isActiveQuantity = item.isActiveQuantity;
            this.isActiveDiscount = item.isActiveDiscount;
            this.isActive = item.isActive;
            this.wattage = item.wattage;
            this.warranty = item.warranty;
            this.imageUrl = item.image ? pathImageUrl + item.image : null;
            this.dialogAddItem = true;
        },

        showDialogDelete(item) {
            if (this.selectedItems.length < 1) {
                if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
                    this.disableChangeDelete = item.deletedAt;
                    this.dialogDelete = true;
                    this.deleteItemIDs.push(item.id);
                }
            } else {
                this.dialogDelete = true;
            }

        },
        hideDialogDelete() {
            this.dialogDelete = false;
            this.disableChangeDelete = false;
            this.deleteItemIDs = [];
            this.selectedItems = [];
        },
        destroyItems() {

            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];

                if (this.isForceDeletedRow)
                    currentSelected = this.selectedItems;
                else
                    currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


                currentSelected.map((e) => this.deleteItemIDs.push(e.id));
            }


            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false
            } else {
                networks.fetchFromWeb(urlAddons + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();
                            alert("Data has been deleted");
                            this.getAddonsFromApi();
                        }

                    })
                    .catch(() => {
                        alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);
            }

        },

        restoreItems(item) {
            this.buttonLoading = true;
            if (this.selectedItems.length > 0) {
                let currentSelected = [];
                currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
                currentSelected.map((e) => this.deleteItemIDs.push(e.id));

            } else {
                this.deleteItemIDs.push(item.id);
            }

            if (this.deleteItemIDs.length < 1) {
                this.hideDialogDelete();
                this.buttonLoading = false;
            } else {

                networks.fetchFromWeb(urlAddons + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
                    .then(response => {

                        if (response.status == 200) {
                            this.hideDialogDelete();

                            this.getAddonsFromApi();
                        }

                    })
                    .catch(() => {
                        alert("Failed for process delete data");
                        this.hideDialogDelete();
                    }).finally(() => this.buttonLoading = false);

            }
        }


    },
}
</script>



